@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Inter-Regular.woff2") format("woff2");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("../fonts/Inter-Italic.woff2") format("woff2");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/Inter-Medium.woff2") format("woff2");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 500;
    font-display: swap;
    src: url("../fonts/Inter-MediumItalic.woff2") format("woff2");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Inter-Bold.woff2") format("woff2");
}
@font-face {
    font-family: "Inter";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("../fonts/Inter-BoldItalic.woff2") format("woff2");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("../fonts/Inter-ExtraBold.woff2") format("woff2");
}

@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("../fonts/Inter-Black.woff2") format("woff2");
}

:root {
    --mnw-color-success: #5BB98C;
    --mnw-color-warning: #AD5700;
    --mnw-color-warning-background: #FFF4D5;
    --mnw-color-error: #CD2B31;
    --mnw-color-error-background: #FFEFEF;
    /* @todo Need to decide what these colors for and rename it */
    --mnw-color-1: #0072ce;
    --mnw-color-2: #006adc;
    --mnw-color-3: #f8f9fa;
    --mnw-color-4: #24272a;
    --mnw-color-5: #dfe3e6;
}
